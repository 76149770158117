import img1 from "../../src/component/images/c24_logo.png";
import "./header.css";
import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import chessIcon from "../component/images/icons/chess.png";
import tictac from "../component/images/icons/tictac.png";
import ludo from "../component/images/icons/ludo.png";

const Games = ({ isDarkMode = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    //handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <span>
      <span
        onClick={handleClick}
        className={isDarkMode ? "header-links-dm" : "header-links"}
      >
        Games
      </span>
      <Menu
        id="basic-menu"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            //history.push(`${path}/ludo`);
            handleClose();
          }}
        >
          <img style={{ marginRight: 10 }} height={22} src={ludo} />
          <a href="https://chat24.in/chat/ludo" className="header-links">
            Ludo
          </a>
        </MenuItem>
        <MenuItem
          onClick={() => {
            //history.push(`${path}/chess`);
            handleClose();
          }}
        >
          <img style={{ marginRight: 10 }} height={20} src={chessIcon} />
          <a href="https://chat24.in/chat/chess" className="header-links">
            Chess
          </a>
        </MenuItem>
        <MenuItem
          onClick={() => {
            //history.push(`${path}/tictaktow`);
            handleClose();
          }}
        >
          <img style={{ marginRight: 10 }} height={18} src={tictac} />
          <a href="https://chat24.in/chat/tictaktow" className="header-links">
            Tic Tac
          </a>
        </MenuItem>
      </Menu>
    </span>
  );
};

const Header = ({ title = "chat/24", isDarkMode = false }) => {
  return (
    <header
      style={{
        zIndex: 1,
        display: "flex",
        background: isDarkMode ? "#333" : "#fff",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        borderBottom: "solid 2px #0fbd49",
        boxShadow: isDarkMode ? "0px 0px 21px #000" : "0px 0px 21px #ccc",
      }}
    >
      <span
        //onClick={()=>{}}
        style={{
          margin: "10px 20px 5px 20px",
          cursor: "pointer",
        }}
      >
        <a
          style={{ textDecoration: "none", color: "#000" }}
          href="https://chat24.in/"
        >
          <img
            alt="chat 24 logo"
            //className="logo-image"
            style={{ boxShadow: "0px 0px 5px #cccc", borderRadius: 3 }}
            height={30}
            width={30}
            src={img1}
          ></img>
        </a>
      </span>

      <span
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: 10,
        }}
      ></span>
      <div style={{ display: "flex", alignItems: "center", marginRight: 10 }}>
        <Games isDarkMode={isDarkMode}></Games>
        <span>
          <a
            href="https://chat24.in/chat"
            className={isDarkMode ? "header-links-dm" : "header-links"}
          >
            Online Chat
          </a>
        </span>
        <span>
          <a
            href="https://chat24.in/blog"
            className={isDarkMode ? "header-links-dm" : "header-links"}
          >
            Blog
          </a>
        </span>
        <span>
          <a
            href="https://chat24.in/about-us"
            className={isDarkMode ? "header-links-dm" : "header-links"}
          >
            About
          </a>
        </span>
      </div>
    </header>
  );
};

export default Header;
