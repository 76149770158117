import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentDiceState,
  currentPlayerState,
  socketInfo,
} from "../recoil/atoms";
import diceRollSound from "../assets/diceRoll2.mp3";
import d1 from "../../../../component/images/icons/d1.png";
import d2 from "../../../../component/images/icons/d2.png";
import d3 from "../../../../component/images/icons/d3.png";
import d4 from "../../../../component/images/icons/d4.png";
import d5 from "../../../../component/images/icons/d5.png";
import d6 from "../../../../component/images/icons/d6.png";
import rollDice from "../../../../component/images/icons/dice2.png";

var currentUser;
/**
 * Component for DICE.
 * @returns JSX
 */

const images = {
  1: <img src={d1}></img>,
  2: <img src={d2}></img>,
  3: <img src={d3}></img>,
  4: <img src={d4}></img>,
  5: <img src={d5}></img>,
  6: <img src={d6}></img>,
};

function Dice({ socket }) {
  const [isAnimating, setIsAnimating] = useState(false);
  const [diceState, setDiceState] = useRecoilState(currentDiceState);
  const currentPlayer = useRecoilValue(currentPlayerState);
  const [socketInf, setSocketInfo] = useRecoilState(socketInfo);
  currentUser = socketInf.user;
  useEffect(() => {}, [diceState, currentPlayer]);
  // Generates random dice value between 1 and 6
  const rotateDice = (data) => {
    //alert(JSON.stringify(data));
    // setDiceState({
    //   // num: 6,
    //   num: Math.ceil(Math.random() * 6),
    //   isLocked: true,
    //   lastRolledBy: currentPlayer,
    // });
    socket.emit("setDiceState1", {
      num: Math.ceil(Math.random() * 6),
      isLocked: true,
      lastRolledBy: currentPlayer,
      room_no: data.room_no,
      user: data.user,
    });
  };

  // Event handler for dice onClick
  const onClick = (e) => {
    if (socketInf.playerInfo && socketInf.playerInfo[socketInf.user]) {
      if (socketInf.playerInfo[socketInf.user] == currentPlayer) {
      } else {
        alert("its apponent turn");
        return;
      }
    }
    e.stopPropagation();
    if (!isAnimating && !diceState.isLocked) {
      //setDiceState({ num: 0, isLocked: false, lastRolledBy: currentPlayer });
      socket.emit("setDiceState", {
        num: 0,
        isLocked: false,
        lastRolledBy: currentPlayer,
        ...socketInf,
      });
      //setIsAnimating(true);
      socket.emit("setIsAnimating", { data: true, ...socketInf });
      new Audio(diceRollSound).play();
      if (socketInf.playerInfo && socketInf.playerInfo[socketInf.user]) {
        //socket.emit("setPlayerInfo", { [currentPlayer]: socketInf.user });
        //alert(socketInf.user + " -- " + currentPlayer);
        //alert("all set");
      } else {
        //alert("send data");
        socket.emit("setPlayerInfo", {
          ...socketInf,
          playerInfo: {
            ...socketInf.playerInfo,
            [socketInf.user]: currentPlayer,
          },
        });
        //alert("players all set");
      }

      // setTimeout(() => {
      //   rotateDice();
      //   setIsAnimating(false);
      // }, 1000);
    }
  };

  useEffect(() => {
    socket?.on("setDiceState1", function (data) {
      setDiceState({
        num: data.num,
        isLocked: data.isLocked,
        lastRolledBy: data.lastRolledBy,
      });
    });

    socket?.on("setDiceState", function (data) {
      setDiceState({
        num: data.num,
        isLocked: data.isLocked,
        lastRolledBy: data.lastRolledBy,
      });
    });
    socket?.on("setIsAnimating", function (data) {
      setIsAnimating(data.data);
      setTimeout(() => {
        if (currentUser == data.user) {
          rotateDice(data);
        }
        setIsAnimating(false);
      }, 1000);
    });

    socket?.on("setPlayerInfo", function (data) {
      //alert(JSON.stringify(socketInf));
      setSocketInfo({ ...data, user: currentUser });
    });
  }, [socket]);

  return (
    <div
      className={"dice" + (isAnimating ? " dice-animation" : "")}
      onClick={onClick}
      style={{
        backgroundImage: diceState.num ? null : `url(${rollDice})`,
        backgroundSize: "cover",
        height: socketInf.isMobile ? 30 : 35,
        width: socketInf.isMobile ? 35 : 40,
        gridTemplateColumns: diceState.num > 1 ? "repeat(2, 1fr)" : "1fr",
        display: "flex",
        justifyContent: "center",
        padding: 13,
      }}
    >
      {<div>{images[diceState.num]}</div>}
    </div>
  );
}

export default Dice;
