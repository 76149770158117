// import Chessboard from "chessboardjsx";
import { Chessboard } from "react-chessboard";

import React, { useRef, useState, useEffect } from "react";
import { Chess } from "chess.js";
import GameInfo from "../../component/games/game_info";

const App = (props) => {
  const [game, setGame] = useState(new Chess());
  const [moveFrom, setMoveFrom] = useState("");
  const [rightClickedSquares, setRightClickedSquares] = useState({});
  const [moveSquares, setMoveSquares] = useState({});
  const [optionSquares, setOptionSquares] = useState({});
  const [playerInfo, setPlayerInfo] = useState({});

  useEffect(() => {
    if (props.chessData.playerInfo) {
      setPlayerInfo({ ...playerInfo, ...props.chessData.playerInfo });
    }
    let copyGame = new Chess(props.chessData.fen);
    setGame(copyGame);
  }, [props.chessData]);

  function safeGameMutate(modify) {
    setGame((g) => {
      const update = { ...g };
      modify(update);
      return update;
    });
  }

  function getMoveOptions(square) {
    const moves = game.moves({
      square,
      verbose: true,
    });
    if (moves.length === 0) {
      return;
    }

    const newSquares = {};
    moves.map((move) => {
      newSquares[move.to] = {
        background:
          game.get(move.to) &&
          game.get(move.to).color !== game.get(square).color
            ? "radial-gradient(circle, rgba(0,0,0,.1) 85%, transparent 85%)"
            : "radial-gradient(circle, rgba(0,0,0,.1) 25%, transparent 25%)",
        borderRadius: "50%",
      };
      return move;
    });
    newSquares[square] = {
      background: "rgba(255, 255, 0, 0.4)",
    };
    setOptionSquares(newSquares);
  }

  function onSquareClick(square) {
    if (playerInfo != {} && playerInfo[props.user]) {
      if (playerInfo[props.user] != game._turn) {
        alert("its apponent turn");
        return;
      }
    }
    setRightClickedSquares({});

    function resetFirstMove(square) {
      setMoveFrom(square);
      getMoveOptions(square);
    }

    // from square
    if (!moveFrom) {
      resetFirstMove(square);
      return;
    }

    // attempt to make move
    //alert(game.fen());
    console.log("2222STAR2222");

    const gameCopy = new Chess(game.fen());
    //console.log("====------======", gameCopy._turn);

    let move;
    try {
      let playerInfo = { [props.user]: gameCopy._turn };
      move = gameCopy.move({
        from: moveFrom,
        to: square,
        promotion: "q", // always promote to a queen for example simplicity
      });
      props.socket.emit("fen", {
        fen: gameCopy.fen(),
        room_id: props.room_id,
        user: props.user,
        playerInfo: playerInfo,
      });

      //setGame(gameCopy);
    } catch (err) {
      console.log("err", err);
      move = null;
    }

    // if invalid, setMoveFrom and getMoveOptions
    if (move === null) {
      resetFirstMove(square);
      setMoveFrom("");
      setOptionSquares({});
      return;
    }

    //setTimeout(makeRandomMove, 300);
    setMoveFrom("");
    setOptionSquares({});
  }

  return (
    <div
      style={{
        position: `${props.isMobile ? "fixed" : "inherit"}`,
        top: 10,
        zIndex: 2,
      }}
    >
      <Chessboard
        id="ClickToMove"
        animationDuration={200}
        arePiecesDraggable={false}
        position={game.fen()}
        onSquareClick={onSquareClick}
        customBoardStyle={{
          borderRadius: "4px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
        }}
        customSquareStyles={{
          ...moveSquares,
          ...optionSquares,
          ...rightClickedSquares,
        }}
        boardWidth={props.isMobile ? 200 : 300}
      />
      <GameInfo playerInfo={playerInfo} game="chess" info={""}></GameInfo>

      {/* <GameInfo playerInfo={playerInfo}></GameInfo> */}
    </div>
  );
};
export default App;
