import { useRecoilValue } from "recoil";
import {
  allCoinState,
  currentDiceState,
  currentPlayerState,
  currentPlayersListState,
  socketInfo,
} from "../recoil/atoms";
import Coin from "./coin";

// This component is for 4 blocks that stores COIN in initial state.
function HomeBox({ parent, socket }) {
  const coinState = useRecoilValue(allCoinState);
  const currentPlayer = useRecoilValue(currentPlayerState);
  const playersList = useRecoilValue(currentPlayersListState);
  const userDetails = useRecoilValue(socketInfo);

  //console.log("ssssss", userDetails.playerInfo, parent);
  // const currentDiceNum = useRecoilValue(currentDiceState);

  return (
    <div className={"homeBox"}>
      {currentPlayer === parent && (
        <div className={"homeBoxOverlay glow"}></div>
      )}
      {userDetails.playerInfo && (
        <div style={{ position: "absolute" }}>
          {Object.entries(userDetails.playerInfo).map((obj) => {
            return <div>{obj[1] == parent && obj[0]}</div>;
          })}
        </div>
      )}
      {Array(4)
        .fill()
        .map((_, i) => (
          <div
            key={i}
            className={"homeBoxInner"}
            style={{
              border: `${
                userDetails.isMobile ? "13px" : "20px"
              } solid ${parent}`,
            }}
          >
            {playersList.includes(parent) &&
              coinState[parent][parent[0] + i].position === "home" && (
                <Coin socket={socket} parent={parent} index={parent[0] + i} />
              )}
          </div>
        ))}
    </div>
  );
}

export default HomeBox;
