import React from "react";
import { Helmet } from "react-helmet";
import HEADER from "../../src/dynamic_blog/header";
import HashLoader from "react-spinners/BarLoader";
function Load() {
  return (
    <div
      style={{
        color: "#333",
        backgroundColor: "#f5f5f5",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=1.0"
        />
      </Helmet>

      <HEADER></HEADER>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#fff",
        }}
      >
        <span style={{ opacity: 0.5, paddingBottom: 5, fontWeight: "500" }}>
          loading...
        </span>
        <HashLoader color={"#0fbd49"} loading={true} width={200} />
      </div>
    </div>
  );
}

export default Load;
