import React, { useRef, useState, useEffect } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { CloseOutlined, PersonAdd, Refresh } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const GameInfo = ({ playerInfo, info, game = "chess" }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    //handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeGame = () => {
    let location = window.location.pathname;
    const games = ["/ludo", "/chess", "/tictaktow"];
    games.forEach((game) => {
      location = location.replace(game, "");
    });
    history.push(location);
    setAnchorEl(null);
  };

  const [playerInfo1, setPlayer] = useState(
    playerInfo != {} ? Object.entries(playerInfo) : []
  );

  useEffect(() => {
    if (playerInfo != {}) setPlayer(Object.entries(playerInfo));
  }, [playerInfo]);
  return (
    <div
      style={{
        backgroundColor: "#000",
        opacity: 0.4,
        display: "flex",
      }}
    >
      <span
        style={{
          opacity: 1,
          color: "yellow",
          margin: 0,
          padding: 3,
          fontWeight: 600,
          fontSize: 12,
        }}
        className="game-hint"
      >
        {/* Info for chess  */}
        {game == "chess" &&
          playerInfo1.map((v) => {
            return (
              <span key={v[1]}>{`${v[0]}=${
                v[1] == "w" ? "white" : "black"
              },  `}</span>
            );
          })}

        {/* Info for tic tac tow  */}
        {game == "tictac" && info}
        {game == "ludo" && info}
      </span>
      <span style={{ marginLeft: "auto" }}>
        <i
          class="fa fa-cog"
          style={{
            color: "#fff",
            fontSize: 16,
            marginLeft: "auto",
            padding: "2px 5px",
            alignSelf: "center",
          }}
          aria-hidden="true"
          onClick={handleClick}
        ></i>
        <Menu
          id="basic-menu"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* <MenuItem
            onClick={() => {
              //history.push(`${path}/ludo`);
              handleClose();
            }}
          >
            <Refresh fontSize="small" style={{ marginRight: 10 }} />
            Start New Game
          </MenuItem>
          <MenuItem
            onClick={() => {
              //history.push(`${path}/chess`);
              handleClose();
            }}
          >
            <PersonAdd fontSize="small" style={{ marginRight: 10 }} />
            Invite Friend
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              //history.push(`${path}/tictaktow`);
              closeGame();
            }}
          >
            <CloseOutlined fontSize="small" style={{ marginRight: 10 }} />
            Close
          </MenuItem>
        </Menu>
      </span>
    </div>
  );
};

export default GameInfo;
