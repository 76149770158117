// TODO : this list of url should be comming from data base
// this is temporary sollution to avoid too many mongo db request
// with this approach you will have to add newly added blogs url here
import img1 from "../component/images/g11.jpg";
import img2 from "../component/images/chat24.jpg";
import img3 from "../component/images/b1.jpg";
import img4 from "../component/images/image-share1.PNG";
import img5 from "../component/images/l2.jpg";
import img6 from "../component/images/cs1.PNG";
import img7 from "../component/images/d2.PNG";

export const static_blog_url = [
  {
    url: "/desktop-vs-laptop-what-should-you-buy-for-wfh",
  },
  {
    url: "/best-math-teacher-in-kareli-allahabad",
  },

  {
    url: "/biggest-town-in-India-2021-survey",
  },
  {
    url: "/how-many-latter-can-human-read-in-a-minute",
  },
  {
    url: "/why-cat-touches-your-leg",
  },
  {
    url: "/why-corona-spreading-so-fast-in-India",
  },
  {
    url: "/why-corona-virus-came",
  },

  {
    url: "/kon-hai-godi-media",
  },
  {
    url: "/how-to-register-for-vaccination-age-18-to-44-years-in-India",
  },
  {
    url: "/vaccine-ke-liye-kaise-register-kare-18-saal-ya-uper",
  },
  {
    url: "/how-to-earn-money-online-with-no-education",
  },
  {
    url: "/chances-of-death-from-corona-virus-age-wise",
  },
  {
    url: "/how-to-make-money-online-in-india",
  },
  {
    url: "/random-chat-online",
  },
  {
    url: "/how-to-download-free-movie-on-iphone",
  },
  {
    url: "/how-to-make-money-without-doing-any-work",
  },
  {
    url: "/how-to-make-money-without-doing-any-work",
  },
  {
    url: "/testing-testing-chat-24",
  },
  {
    url: "/testing-title",
  },
  {
    url: "/Hey-this-is-free-tool-for-chat-anyone",
  },
  {
    url: "/testing-123-testing-123",
  },
  {
    url: "/world-is-going-to-end-in-2024",
  },
];

export const blog_card_data = [
  {
    url: "https://chat24.in/chat-with-random-girsl",
    title: "Chat with random girls",
    image: img1,
    content:
      "Chat anonymously with girls, find girls near you and connect with them instantly online, learn the skill of flirting make jokes and share feelings, no cost no subscription totally free chatting platform lifetime",
  },
  {
    url: "https://chat24.in/chat-with-unknown-person-india",
    title: "Chat with random indias person",
    image: img2,
    content:
      "Chat anonymously with Indians,The best place to share thoughts with strangers, find all Indian user and talk in the local language, and share images and photos with each other",
  },
  {
    url: "https://chat24.in/why-breakup-hurts",
    title: "Why breakup is so painful 😔",
    image: img3,
    content:
      "breakup always hurts and its normal, reason for the pain is related to our survival, the pain is a way our body tells us do something",
  },
  {
    url: "https://chat24.in/chat-24",
    title: "Chat 24 hours on Mobile with strangers",
    image: img4,
    content:
      "Chat 24*7 on Mobile with strangers, instantly find random strangers to chat with every day, share images and videos with each other and make new friends",
  },
  {
    url: "https://chat24.in/why-we-feel-lonely-sometimes",
    title: "why do we feel lonely sometimes",
    image: img5,
    content:
      "know why we feel lonely sometimes and why it hurts, there is a scientific reason behind this loneliness, and Know how to deal with loneliness",
  },
  {
    url: "https://chat24.in/chat-with-strangers",
    title: "Chat with strangers unisg emoji",
    image: img6,
    content:
      "Emojis are fun and make chatting more engaging join now and chat with emojis, use hundreds of web emojis free, and get auto-suggested emoji on keyboard",
  },
  {
    url: "https://chat24.in/talk-to-strangers",
    title: "talk to strangers online",
    image: img7,
    content:
      "find thousands of online strangers & talk as anonymously, no login or contact details require, great user experience, and you can also play games along with chat",
  },
];
